/**
 * Gallery
 * @description : Gallery slider with thumbnail menu
 */

(function () {

    var galleryEls = Array.from(document.querySelectorAll('.js-gallery'));

    galleryEls.forEach(function (galleryEl) {
        var swiperEl = galleryEl.querySelector('.js-gallery__swiper');
        var slideEls = Array.from(galleryEl.querySelectorAll('.js-gallery__slide'));

        var thumbListEl = galleryEl.querySelector('.js-gallery__thumb-list');
        var thumbButtonEls = Array.from(galleryEl.querySelectorAll('.js-gallery__thumb-button'));
        var showMoreButtonEl = galleryEl.querySelector('.js-gallery__more-button');

        var swiper = new Swiper(swiperEl, {
            loop: 'true',
            spaceBetween: 20,
            navigation: {
                nextEl: galleryEl.querySelector('.js-gallery__swiper-next'),
                prevEl: galleryEl.querySelector('.js-gallery__swiper-prev'),
            }
        });

        thumbButtonEls.forEach(function (thumbButtonEl) {
            var key = thumbButtonEl.getAttribute('data-key');
            var slideEl = slideEls.filter(function (slideEl) {
                return slideEl.getAttribute('data-key') == key;
            })[0];
            thumbButtonEl.addEventListener('click', function () {
                swiper.slideToLoop(parseInt(slideEl.getAttribute('data-swiper-slide-index')));
            });
        });

        showMoreButtonEl.addEventListener('click', function () {
            thumbListEl.classList.remove('is-limited');
        });
    });
    
})();